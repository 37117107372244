<template>
  <div>
    <div class ="topbanner flexRow" style="margin-bottom:10px">
      <img src="@/assets/img/5d265653aef7f06427449aa54265263.png" style="width:60px;height:60px" alt="">
      <div class = "flexCol" style="margin-left:10px;flex:1">
        <div class = "titfont">消息订阅设置</div>
        <div class = "secfont">针对商家新增短信通知：小程序审核结果通知、短信剩余数量不足通知、系统更新通知、
          商城账户余额通知、供货市场分销货款通知等。你可以在该页面开启需要接收到的短信通知，该短信发送不扣减你商城的短信费用。已订阅的通知将在每天
          <span style="color:#f56c6c">7点~21点</span>
          间发送
          </div>
      </div>
    </div>
    <el-card v-loading = "loading">
      <div class = "flexRow" style="flex-wrap:wrap">
        <div class = "graybox" v-for="(v,i) in datalist" :key="i" v-if="v.Type!=4">
          <div class = "flexRow" style="justify-content: space-between;">
            <div class = "flexRow" style="width:70%">
              <div class = "titlefont" v-if="v.Type!=3">{{v.TypeValue}}</div>
              <div class = "titlefont" v-else>商城账户余额不足通知</div>
              <div class = "line"></div>
              <div class = "subscribefont" v-if="!v.IsOpen">未订阅</div>
              <div class = "subscribefont" style="color:#67C23A" v-else>已订阅</div>
            </div>
            <div style="flex:1;text-align:right">
              <div v-if="!v.IsOpen">
                <div v-if="!v.editshow">
                  <el-button type="text" @click="openedit(i,true)">开启</el-button>
                </div>
                <div v-else>
                  <el-button type="text" @click="save(v)">保存</el-button>
                  <el-button type="text" style="color:#606266" @click="canceleditshow(i)">取消</el-button>
                </div>
              </div>
              <div v-else>
                <div v-if="!v.editshow">
                  <el-button type="text" @click="openedit(i,true)">编辑</el-button>
                  <el-button type="text" style="color:#f56c6c" @click="closenotice(i,false)">关闭</el-button>
                </div>
                 <div v-else>
                  <el-button type="text" @click="save(v)">保存</el-button>
                  <el-button type="text" style="color:#606266" @click="canceleditshow(i)">取消</el-button>
                </div>
              </div>
            </div>
          </div>
          <div class = "flexRow" style="align-items: flex-start;margin-top:10px">
            <div class = "leftfont">发送时间点:</div>
            <div  class = "rigthfont">
              <div v-if="v.Type == 1">
                <span>在小程序有审核结果（审核通过或审核否决）时，给商家发送短信通知</span>
              </div>
              <div v-if="v.Type == 2">
                <span>在商城短信剩余数量不足</span>
                <span v-if="!v.editshow">{{v.Quantity}}</span>
                <el-input v-else v-model="v.numsms" style="width:80px;margin:0px 10px" @blur="blurnum(v)"
                onKeypress="return (/[\d]/.test(String.fromCharCode(event.keyCode)))"></el-input>
                <span>条时，给商家发送短信通知</span>
              </div>
              <div v-if="v.Type == 3">
                <span>当商城免费订单额度不足</span>
                <span v-if="!v.editshow">{{v.Quantity}}</span>
                <el-input v-else v-model="v.numorder" style="width:80px;margin:0px 10px" @blur="blurorder(v)"
                onKeypress="return (/[\d]/.test(String.fromCharCode(event.keyCode)))"></el-input>
                <span>单，或商城账户余额不足</span>
                <span v-if="!v.editshow">{{datalist.find((v)=>{return v.Type==4}).Quantity}}</span>
                <el-input v-else v-model="v.numbalance" style="width:80px;margin:0px 10px" @blur="blurbalance(v)"
                onKeypress="return (/[\d]/.test(String.fromCharCode(event.keyCode)))"></el-input>
                <span>元时，给商家发送短信通知</span>
              </div>
              <div v-if="v.Type == 5">
                <span>当商城供货市场分销货款余额不足</span>
                <span v-if="!v.editshow">{{v.Quantity}}</span>
                <el-input v-else v-model="v.numpayment" style="width:80px;margin:0px 10px" @blur="blurPayment(v)"
                onKeypress="return (/[\d]/.test(String.fromCharCode(event.keyCode)))"></el-input>
                <span>元时，给商家发送短信通知</span>
              </div>
              <div v-if="v.Type == 6">
                <span>当系统发布最新功能时，企店将以短信的方式给商家发送通知</span>
              </div>
            </div>
          </div>
          <div class = "flexRow" style="align-items:center;margin-top:15px">
            <div class = "leftfont">接收手机号:</div>
            <div  class = "rigthfont flexRow" style="height:30px">
              <span v-if="!v.editshow">{{v.Phone}}</span>
              <el-input v-else v-model="v.editphone" onKeypress="return (/[\d]/.test(String.fromCharCode(event.keyCode)))"
               @blur="blurphone(v)" maxlength="11" style="width:200px"></el-input>
            </div>
          </div>
        </div>
      </div>
    </el-card>
  </div>
</template>

<script>
import {
  mallmallNoticeSubscribeConfig,
  mallmallNoticeSubscribeConfigSave
}from "@/api/sv3.0.0"
export default {
  data () {
    return {
      loading:false,
      datalist:[],
      cantsave:{
        can:true,
        message:'',
      }
    }
  },
  created () {
    this.getinfo()
  },
  methods: {
    blurPayment(e){
      if(e.numpayment<1000||e.numpayment>99999){
        this.$message.error('货款余额请输入在1000~99999，整数')
        this.cantsave = {
          can:false,
          message:'货款余额请输入在1000~99999，整数'
        }
        return
      }
      this.cantsave = {
        can:true,
      }
    },
    blurorder(e){
      if(e.numorder<100||e.numorder>99999){
        this.$message.error('剩余单数请输入在100~99999，整数')
        this.cantsave = {
          can:false,
          message:'剩余单数请输入在100~99999，整数'
        }
        return
      }
      this.cantsave = {
        can:true,
      }
    },
    blurbalance(e){
      if(e.numbalance<100||eval.numbalance>99999){
        this.$message.error('余额请输入在100~99999，整数')
        this.cantsave = {
          can:false,
          message:'余额请输入在100~99999，整数'
        }
        return
      }
      this.cantsave = {
        can:true,
      }
    },
    blurnum(e){
      if(e.numsms<100||e.numsms>99999){
        this.$message.error('短信剩余数量请输入在100~99999，整数')
        this.cantsave = {
          can:false,
          message:'短信剩余数量请输入在100~99999，整数'
        }
        return
      }
      this.cantsave = {
        can:true,
      }
    },
    closenotice(e,open){
      this.cantsave = {
        can:true,
      }
      this.datalist[e].editopen = open
      // console.log(this.datalist[e],6)
      this.save(this.datalist[e])
    },
    async save(e){
      if(!this.cantsave.can){
        this.$message.error(this.cantsave.message)
        return 
      }
      this.loading = true
      try{
        // let i = await e
        // console.log(this.datalist)
        // let data = this.datalist.find((v)=>{
        //   return v.Type == i
        // })
        let data = await e
        // console.log(data,e)
        data.Phone = data.editphone
        data.IsOpen = data.editopen
        if(data.Type==2){
          data.Quantity = data.numsms
        }else if(data.Type==3){
          data.Quantity = data.numorder
        }else if(data.Type==4){
          let type3 = this.datalist.find((v)=>{
            return v.Type == 3
          })
          data.Quantity = type3.numbalance
          data.IsOpen = type3.editopen
          data.Phone = type3.Phone
        }else if(data.Type==5){
          data.Quantity = data.numpayment
        }
        // console.log(data)
        let res = await mallmallNoticeSubscribeConfigSave(data)
        // console.log(i)
        if(res.IsSuccess){
          if(data.Type==3){
            this.save(this.datalist.find((v)=>{
              return v.Type == 4
            }))
          }else{
            this.$message.success('操作成功')
            this.getinfo()
          }
        }
      }catch{
        this.getinfo()
      }finally{
        this.loading = false
      }
    },
    blurphone(e){
      if(!e.editphone){
        this.$message.error('请输入接收手机号')
        this.cantsave = {
          can:false,
          message:'请输入接收手机号',
        }
        return 
      }else if(e.editphone.length <11){
        this.$message.error('请输入正确的手机号')
        this.cantsave = {
          can:false,
          message:'请输入正确的手机号',
        }
        return 
      }
      this.cantsave = {
        can:true,
      }
    },
    openedit(e,open){
      this.datalist[e].editshow = true
      this.datalist[e].editphone = this.datalist[e].Phone
      this.datalist[e].editopen = open
      if(this.datalist[e].Type==2){
        this.datalist[e].numsms = this.datalist[e].Quantity?this.datalist[e].Quantity:500
        // console.log(this.datalist[e])
      }else if(this.datalist[e].Type==3){
        this.datalist[e].numorder = this.datalist[e].Quantity?this.datalist[e].Quantity:100
        let numbalance = this.datalist.find((v)=>{
          return v.Type == 4
        }).Quantity
        this.datalist[e].numbalance = numbalance?numbalance:100
      }else if(this.datalist[e].Type==5){
        this.datalist[e].numpayment = this.datalist[e].Quantity?this.datalist[e].Quantity:1000
      }
      console.log(this.datalist[e])
    },
    canceleditshow(e){
      this.datalist[e].editshow = false
      this.cantsave = {
        can:true,
      }
    },
    async getinfo(){
      try{
        this.loading = true
        let res = await mallmallNoticeSubscribeConfig()
        if(res.IsSuccess){
          console.log(res.Result)
          this.datalist = res.Result.map((v)=>{
            v.editshow = false
            v.editphone = v.Phone
            v.editopen = v.IsOpen
            if(v.Type==2){
              v.numsms = v.Quantity?v.Quantity:500
            }else if(v.Type==3){
              v.numorder = v.Quantity?v.Quantity:100
              let numbalance = res.Result.find((x)=>{
                return x.Type == 4
              }).Quantity
              v.numbalance = numbalance?numbalance:100
            }else if(v.Type==5){
              v.numpayment = v.Quantity?v.Quantity:1000
            }
            return v
          })
        }
      }finally{
        this.loading = false
      }
    },
  },
}
</script>

<style lang = "less" scoped>
.flexRow{
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
  /* align-items: flex-start; */
}
.flexCol{
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.topbanner{
  background: white;
  padding: 10px 20px;
  .titfont{
    font-size: 14px;
    color: #303133;
    line-height: 1.5;
  }
  .secfont{
    color: #606266;
    font-size: 12px;
    line-height: 1.5;
    margin-top:5px;
  }
}
.graybox{
  width: 450px;
  background: #F8F8F9;
  border-radius: 2px 2px 2px 2px;
  opacity: 1;
  padding: 10px 15px 20px 15px;
  flex-shrink: 0;
  margin: 0px 50px 40px 0px;
  .titlefont{
    font-size: 14px;
    font-weight: 400;
    color: #303133;
    line-height: 1.5;
  }
  .line{
    width: 0px;
    height: 10px;
    opacity: 1;
    border: 1px solid #DCDFE6;
    margin: 0px 10px;
  }
  .subscribefont{
    font-size: 14px;
    font-weight: 400;
    color: #999999;
    line-height: 1.5;
  }
  .leftfont{
    width: 80px;
    /* border:1px solid black; */
    font-size: 14px;
    font-weight: 400;
    color: #303133;
    line-height: 36px;
    white-space: nowrap;
    flex-shrink: 0;
  }
  .rigthfont{
    font-size: 14px;
    font-weight: 400;
    color: #303133;
    line-height: 36px;
  }
}
</style>